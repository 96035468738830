<template>
  <div class="flex flex-wrap" data-cy="stage-phases-line">
    <app-phase-tag
      v-for="(phase, index) in activeWorkflowPhases"
      :active-phase="activePhase"
      :client-name="clientName"
      :current-phase="indexOfCurrentPhase || undefined"
      :first="index === 0"
      :index="index"
      :is-managed-by-client="isManagedByClient(index)"
      :is-managed-by-lendflow="isManagedByLendflow(index)"
      :key="phase"
      :last="isLast(String(index))"
      :phase-name="phase"
      :show-completed-icon="hasCompletedPhase(index)"
      :show-dead-icon="isDeadAtPhase(String(index), phase)"
      data-cy="stage-phase-tag"
      @click="handlePhaseClick(phase, index)"
    />
    <div v-if="workflowBuilderName" v-tooltip="workflowBuilderName">
      <icon-base
        class="bg-medium-grey rounded ml-2 p-0.5"
        height="26"
        icon="workflow-linear"
        width="26"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import { useDeals } from "@/hooks/deals";
import { PHASE_COMPLETE, PHASE_ABSTRACT_APPROVED } from "@/helpers/constants";
import type { IRootState } from "@/models/state";
import {
  useActiveStage,
  useActiveWorkflowTemplate,
  useActiveWorkflowTemplateStages
} from "@/hooks/workflow";
import { getDealStatusName } from "@/helpers/formatting";
import { ROUTE_DEAL_PROGRESS } from "@/router/routes";
import { storeToRefs } from "pinia";
import AppPhaseTag from "@/views/deals/components/AppPhaseTag.vue";
import useApplicationsStore from "@/stores/applications";
import { useFullStory } from "@/hooks/fullStory";
import { FullStoryEvent } from "@/enums/events";

const route = useRoute();
const router = useRouter();

const { getters, commit, dispatch } = useStore<IRootState>();
const { activeDeal: deal, activeDealPhases } = useDeals();
const {
  activeTemplateStages,
  currentPhaseName,
  activeWorkflowPhases,
  indexOfCurrentPhase
} = useActiveWorkflowTemplateStages();
const {
  activeStage,
  isUnderwritingStage,
  isApplicationStage,
  isScorecardsStage
} = useActiveStage();
const { activeTemplate } = useActiveWorkflowTemplate();
const applicationsStore = useApplicationsStore();
const { publishEvent } = useFullStory();
const { shouldFetchBusinessNotes, shouldFetchOffers } =
  storeToRefs(applicationsStore);

const activePhase = ref(0);
const noDeadStatus = -1;

const isDeadAtApplication = computed(() => {
  const deadStatusNumber: string = getters["options/deadStatus"];
  return (
    deal.value.status === Number(deadStatusNumber) &&
    deal.value?.death?.previous_status === null
  );
});

const dealAllowedStep = computed(() => {
  let statusName = currentPhaseName.value;

  if (statusName === PHASE_COMPLETE) {
    statusName = PHASE_ABSTRACT_APPROVED;
  }

  return activeWorkflowPhases.value
    .map((phase) => phase.toLowerCase())
    .indexOf(statusName.toLowerCase());
});

const workflowBuilderName = computed(() => activeTemplate.value.name);
const clientName = computed(() => deal.value?.partner?.client?.name);

const isManagedByClient = (index: number) =>
  activeTemplateStages.value[index]?.management?.client;

const isManagedByLendflow = (index: number) =>
  activeTemplateStages.value[index]?.management?.lendflow;

const handlePhaseClick = async (phase: string, index: number) => {
  const phaseIndex = activeWorkflowPhases.value.indexOf(phase);

  if (deal.value.status === 8 && deal.value.status < phaseIndex + 1) {
    return;
  }

  const workflowStage = activeTemplate.value.content?.stages[index];
  commit("workflows/setActiveStage", workflowStage);
  commit("applications/setCurrentPhase", phaseIndex);

  setActivePhaseIndex(index);

  publishEvent(FullStoryEvent.FS_VIEW_STAGE_EVENT, {
    name: workflowStage?.name,
    type: workflowStage?.type
  });

  if (!workflowStage?.name) {
    return;
  }

  if (route.name === ROUTE_DEAL_PROGRESS) {
    router.replace({
      ...route,
      query: {
        ...route.query,
        stage: String(workflowStage.name)
      }
    });
  } else {
    router.replace({
      name: ROUTE_DEAL_PROGRESS,
      params: {
        id: route.params.id
      },
      query: {
        ...route.query,
        stage: String(workflowStage.name)
      }
    });
  }

  if (isApplicationStage.value && shouldFetchBusinessNotes.value) {
    applicationsStore.updateFetchServiceStatus(["businessNotes"], false);
    await dispatch("applications/getBusinessNotes");
  }

  const isOffersStage = [
    isApplicationStage.value,
    isUnderwritingStage.value,
    isScorecardsStage.value
  ].every((stage) => !stage); // if all stages are false, then we are in offers stage

  if (isOffersStage && shouldFetchOffers.value) {
    applicationsStore.updateFetchServiceStatus(["offers"], false);
    await dispatch("applications/getOffers");
  }
};

const setActivePhaseIndex = (index: number) => {
  activePhase.value = index;
};

const isLast = (index: string) => {
  return (
    index === activeWorkflowPhases.value[activeWorkflowPhases.value.length - 1]
  );
};

const hasCompletedPhase = (index: number) => {
  //status deal died in
  const deadStatusId = deal.value?.death
    ? deal.value?.death.previous_status
    : noDeadStatus;

  if (deadStatusId !== noDeadStatus) {
    return indexOfCurrentPhase.value >= index;
  }

  const statusName = activeWorkflowPhases.value[deadStatusId] || "";
  const statusIndex = activeDealPhases.value.indexOf(statusName);
  if (statusName) {
    return statusIndex >= index;
  }

  if (dealAllowedStep.value === activeWorkflowPhases.value.length - 1) {
    return true;
  }

  const finishedPhases = activeWorkflowPhases.value.slice(
    0,
    dealAllowedStep.value || 0
  );
  return finishedPhases.includes(activeWorkflowPhases.value[index]);
};

const isDeadAtPhase = (phaseIndex: string, phaseName: string) => {
  if (isDeadAtApplication.value && phaseIndex === "0") {
    return true;
  }

  if (!deal.value?.death?.previous_status) {
    return false;
  }

  if (indexOfCurrentPhase.value === Number(phaseIndex)) {
    return true;
  }

  const { status_description } = getDealStatusName(
    deal.value?.death?.previous_status
  );

  return phaseName.toLowerCase() === status_description.toLowerCase();
};

watch(activeStage, (stageValue) => {
  const stageIndex = activeWorkflowPhases.value.findIndex(
    (name) => name === stageValue?.name
  );
  setActivePhaseIndex(stageIndex);
});

defineExpose({ setActivePhaseIndex });
</script>
